<template>
  <!-- 系统文章 -->
  <div class="containerbox serverContent">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="addServe()">新建</el-button>

      <el-button class="itemBtn btnColor" @click="editorServe(1)"
        >修改</el-button
      >
      <el-button class="itemBtn btnColor" @click="editorServe(2)"
        >查看详情</el-button
      >
      <el-button class="itemBtn btnColor" @click="isEnableArticle()"
        >启用</el-button
      >
      <el-button class="itemBtn btnColor" @click="unEnableArticle()"
        >禁用</el-button
      >
      <el-button class="itemBtn btnColor" @click="DelServe()">删除</el-button>
    </div>

    <!-- 表格:height="tableHeigth" -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        ref="mytable"
        :data="tableData"
        :max-height="tableHeigth"
        :row-class-name="tableRowClassName"
        highlight-current-row
        @row-click="rowclick"
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          label="类型"
          prop="articleType"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="标题"
          prop="title"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="弹窗提示更新"
          prop="isPopUpShow"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="官网显示"
          prop="officialWebsiteShowShow"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="APP显示"
          prop="appShowShow"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="状态"
          prop="isEnableShow"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          min-width="160"
          label="创建时间"
          prop="createTime"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="创建人"
          prop="createUserName"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="修改人"
          prop="operationUserName"
        ></el-table-column>
      </el-table>
    </div>

    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      class="serverPage"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paging from "@/components/pagings.vue";
export default {
  inject: ["reload"],

  data() {
    return {
      pageNum: 1, //页码
      size: 50, //一页几条,
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      tableData: [], //表格数据
      tableHeigth: 700,
      selectMemberData: [],
    };
  },
  components: {
    paging,
  },
  updated() {},
  created() {},
  mounted() {
    this.getData();
  },
  watch: {},
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    // 禁用
    unEnableArticle() {
      if (this.selectMemberData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
        return false;
      }

      this.$confirm("是否禁用所选文章?", "提示:")
        .then((res) => {
          let ids = [];
          this.selectMemberData.forEach((item) => {
            ids.push(item.articleId);
          });
          let param = {
            articleIds: ids,
          };
          param.sign = tools.getSign(param);
          Api.unEnableArticle(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },
    // 启用
    isEnableArticle() {
      if (this.selectMemberData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
        return false;
      }

      this.$confirm("是否启用所选文章?", "提示:")
        .then((res) => {
          let ids = [];
          this.selectMemberData.forEach((item) => {
            ids.push(item.articleId);
          });
          let param = {
            articleIds: ids,
          };
          param.sign = tools.getSign(param);
          Api.isEnableArticle(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },
    // 删除
    DelServe() {
      if (this.selectMemberData.length < 1) {
        this.$message.warning("请至少勾选一条数据");
        return false;
      }

      this.$confirm("该操作将永久删除该文章,请谨慎操作!", "提示:")
        .then((res) => {
          let ids = [];
          this.selectMemberData.forEach((item) => {
            ids.push(item.articleId);
          });
          let param = {
            articleId: ids,
          };
          param.sign = tools.getSign(param);
          Api.deleteArticle(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },
    addServe() {
      //新增协议
      this.$router.push({
        path: "/pages/AddSystemArticle",
        query: { pageType: "add" },
      });
    },
    // 编辑
    editorServe(num) {
      // num==1 编辑   num==2,详情
      if (this.selectMemberData.length < 1) {
        this.$message.warning("请勾选一条数据");
        return false;
      } else if (this.selectMemberData.length >= 2) {
        this.$message.warning("请勾选一条数据");
      } else {
        let pageId = this.selectMemberData[0].articleId;
        let pageType = num == 1 ? "edit" : "detail";
        this.$router.push({
          path: "/pages/EditSystemArticle",
          query: { id: pageId, pageType: pageType },
        });
      }
    },
    //=>选择表格操作
    handleSelectionChange(e) {
      this.selectMemberData = e;
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let topheight = this.$refs.btnList.offsetHeight;
      let pagsHeigth = 40; //分页组件
      var otherHeight = pagsHeigth + 60 + 10 + topheight; //60是顶部导航 ,10==预留空位

      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 获取数据
    getData() {
      let formData = {
        articleCategory: "xi_tong:article_category", //系统文章=xi_tong:article_category, 攻略文章=gong_lue:article_category
      };
      let that = this;
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      Api.articleList(formData).then((res) => {
        that.tableData = res.data.result.data || [];
        that.total = res.data.result.pageCount || 0;
        that.fetTableHeight();
      });
    },
  },
};
</script>

<style scoped lang="scss">
// .containerbox {
//   height: calc(100vh - 75px);
// }

// .tableBox {
//   height: calc(100% - 180px);
// }
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.imgbox {
  width: 100%;
  margin-left: 12%;
  box-sizing: border-box;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.serverContent {
  .tableBox {
    background-color: #fff;
  }
  .btnList1 {
    width: auto;
    // padding: 10px;
    border-radius: 4px;
  }
}
</style>
